import { Controller } from  "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static targets = ['messages']
  connect(){
    createConsumer().subscriptions.create({
      channel: "ApplicationCable::IncomeChannel",
      room: `${this.element.dataset.income}_${document.body.dataset.tenant}`
    },
    {
      received(data){
        let msg = document.getElementById('msg');
        let element = document.getElementById('report-bg');
        if (element) {
          element.remove();
        }
        if (data.response.status == "stamped"){
          window.location.reload();
        } else {
          msg.classList.add('alert', 'alert-danger');
          msg.textContent = data.response.errors
        }
      }
    })
  }
}
