import { Controller } from  "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static targets = ['messages']
  connect(){
    console.log('conected waybill')
    createConsumer().subscriptions.create({
      channel: "ApplicationCable::WaybillChannel",
      room: `${this.element.dataset.waybill}_${document.body.dataset.tenant}`
    },
    {
      received(data){
        console.log('recived')
        let content = document.getElementById('response');
        let element = document.getElementById('report-bg');
        let msg = document.getElementById('report-message');
        if (msg) {

        }
        if (data.response.status == "finished"){
          msg.innerHTML = `<div class='report-message' id='report-message'>Timbrado exitoso</div>`;
          setTimeout(function(){
            window.location.reload();
          }, 300);
        } else {
          element.remove();
          content.classList.add('alert', 'alert-danger');
          content.textContent = data.response.errors
        }
      }
    })
  }

  setBanner(){
    let bannerElement = ''
    let content = document.getElementById('response');
    console.log(content)
    bannerElement += `
      <div class='report-bg'>
        <div class='report-message' id='report-message'>
          Timbrado en proceso, por favor no salga de esta página
        </div>
    `;
    content.innerHTML = bannerElement
  }
}