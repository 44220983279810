import { Controller } from  "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['catalog', 'totalTrue', 'searchParams', 'submitButton']
  static values = {
    logId: String
  }
 
  connect() {
    if(this.logIdValue != '') this.pollForStatus();
  }

  pollForStatus(){
    $.ajax({
      url: '/check_import_status',
      method: 'GET',
      data: { log_id: this.logIdValue },
      success: response => {
        if(response["file_status"] == 'complete' || response["file_status"] == 'fail'){ 
          debugger
          window.location.href = `import_from_organization?catalog=${catalog.value}&in_process=${response["file_status"]}`;
        }else{
          setTimeout(() => this.pollForStatus(), 3000);
        }
      }
    })
  }

  organizationSelected(e){
    let organization = e.target.value;
    if(e.target.value == '') return;

    let catalog = this.catalogTarget.value;
    window.location.href = `import_from_organization?catalog=${catalog}&source_organization=${organization}`;
  }

  countSelection(e){
    let checkboxArr = Array.from(document.getElementsByName('elements[]'));
    let totalTrue = checkboxArr.filter((inp) => inp.checked == true && inp.disabled == false).length;
    this.totalTrueTarget.innerHTML = totalTrue;
    this.submitButtonTarget.disabled = totalTrue == '0'
  }

  search(e){
    e.preventDefault();
    let elements = Array.from(document.getElementsByName('elements[]'));
    if(this.searchParamsTarget.value == ''){
      elements.forEach((element) => {
        element.parentElement.parentElement.style.display = 'block'
      })
    }else {
      elements.forEach((element) => {
        let parent = element.parentElement;
        let element_label = parent.childNodes[1].innerHTML;
        if(!element_label.toUpperCase().includes(this.searchParamsTarget.value.toUpperCase())){
          parent.parentElement.style.display = 'none';
        }else{
          parent.parentElement.style.display = 'block'
        }
      })
    }
  }

  selectAll(e){
    let checkboxArr = Array.from(document.getElementsByName('elements[]'));
    checkboxArr.forEach((cBox) => {
      if(!cBox.disabled){
        cBox.checked = e.target.checked;
      }
    })
    this.countSelection();
  }
}