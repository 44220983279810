import { Controller } from  "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["chart"];

  connect(){
    const data = JSON.parse(this.chartTarget.dataset.value);
    
    const total = data.reduce((sum, item) => sum + item.value, 0);

    const dataWithPercentages = data.map(item => {
      return {
        label: `${item.label} (${((item.value / total) * 100).toFixed(2)}%)`,
        value: item.value
      };
    });

    new Morris.Donut({
      element: this.element,
      data: dataWithPercentages,
      colors: ['#0b62a4', '#7a92a3', '#4da74d', '#afd8f8'],
      resize: true,
      labels:"totl"
    });

    document.querySelectorAll('path[fill="#0b62a4"]')[0].addEventListener('click', () => this.redirectToReport("reports/quality_reports"))
    document.querySelectorAll('path[fill="#7a92a3"]')[0].addEventListener('click', () => this.redirectToReport("reports/halt_reasons"))
    document.querySelectorAll('path[fill="#4da74d"]')[0].addEventListener('click', () => this.redirectToReport("machinery_times"))
  }

  redirectToReport(reportType){
    window.location = `/${reportType}`
  }
}