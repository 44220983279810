import { Controller } from  "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["currentBudget", "sendBudget", "currentCurrency", "sendCurrency",
    "nextMonth", "sendNextMonth"
  ];

  connect(){

  }

  setCurrentBudget(){
    this.sendBudgetTarget.value = this.currentBudgetTarget.value
    this.sendCurrencyTarget.value = this.currentCurrencyTarget.value
    this.sendNextMonthTarget.value = this.nextMonthTarget.checked
    this.cleanInputs()
  }

  cleanInputs(){
    this.currentBudgetTarget.value = ''
    this.currentCurrencyTarget.value = ''
    this.nextMonthTarget.checked = false
  }
}