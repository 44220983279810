window.addEventListener('DOMContentLoaded', () => {
  let reportModal = document.getElementById("report-modal")
  if(!reportModal) return
  pollForReportStatus(reportModal.dataset.reportId, reportModal)
});

const pollForReportStatus = (reportId, reportModal) => {
  $.ajax({
    url: '/check_report_status',
    method: 'GET',
    data: { report_id: reportId },
    success: response => {
      if(response["file_status"]){
        reportModal.style.display = "none";
        const link = document.createElement('a');
        link.href = response["file"];
        document.body.appendChild(link);
        link.click();
      }else{
        setTimeout(() => pollForReportStatus(reportId, reportModal), 3000);
      }
    }
  })
}