import { Controller } from  "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  connect(){
    createConsumer().subscriptions.create({ 
      channel: "ApplicationCable::ShipmentChannel",
      room: `${this.element.dataset.shipment}_${document.body.dataset.tenant}`
    },
    {
      received(data){
        document.getElementById('shipmentInfo').innerHTML = data.html_info;
        window.index = new ShipmentConcept.Supplies()
      }
    })
  }
}